import React, { useState } from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import GamingNavbar from "./GamingNavbar";

export default function ContactUs(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, seterror] = useState("");

  async function sendMessage(event) {
    event.preventDefault();
    let data = { name, email, message };

    if (!data || !email || !message) {
      return seterror("All fields required");
    }
    if (data && email && message) {
        setName("");
console.log("event",event.target.reset())
      alert("your message send successfully");
      
    }
  }

  return (
    <>
      <GamingNavbar data={props.location.pathname} />
      <div className="container ">
        <div className="row mb-5">
          <div className="col ">
            <h2 className=" mt-2 text-center">Contact Us</h2>
            <p className="text-center">
              At Polar Win we believe in transparent communication and have
              built various touchpoints through Where our Player can get in
              touch with us.
            </p>
          </div>
        </div>
        <div className="row ">
          <div className="col-md-6 col-sm-12 contactUs">
            <div className="row mb-4 mt-4 contactIcon d-flex justify-content-start">
              <div className="col-md-3 ">
                {/* <span className="contactUsIcon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="black"
                    className="bi bi-geo-alt-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                  </svg>
                </span> */}
              </div>
              {/* <div className="col-md-6 contactText ">
                <p className="text-primary fw-bold h5">Address</p>
                <p>270 Hilingdon UB3 5AY United knigdom</p>
              </div> */}
            </div>
            <div className="row mb-5 d-flex contactIcon justify-content-start">
              <div className="col-md-3 ">
                {/* <span className="contactUsIcon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="black"
                    className="bi bi-telephone-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                    />
                  </svg>
                </span> */}
              </div>
              {/* <div className="col-md-4 contactText">
                <p className="text-primary fw-bold h5">Phone</p>
                <p>+447587512095</p>
              </div> */}
            </div>
            <div className="row d-flex  contactIcon justify-content-start">
              <div className="col-md-3 ">
                <span className="contactUsIcon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="black"
                    className="bi bi-envelope-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z" />
                  </svg>
                </span>
              </div>
              <div className="col-md-4 contactText">
                <p className="text-primary fw-bold h5">Email</p>
                <a  style={{color:'white'}}  href="mailto: info@polarverse.org">info@polarverse.org</a>
              </div>
            </div>
          </div>

          <div className="col contactus py-5 px-5 mx-5 shadow-lg bg-body rounded mb-5">
            {/* <form onSubmit={sendMessage}> */}
            <form action="mailto:polarwintoken@gmail.com" method="post"
             encType="multipart/form-data" name="EmailForm" >
              <h5>Send Message</h5>
              <div className="row ">
                <div className="col-md-12">
                  <label htmlFor="inputEmail4" className="form-label">
                    Full Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Full name"
                    aria-label="First name"
                  />
                </div>
                <div className="col-md-12">
                  <label htmlFor="inputEmail4" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    onChange={(e) => setEmail(e.target.value)}
                    id="inputEmail4"
                    placeholder="Abc@gmail.com"
                  />
                </div>
              </div>
              <div className="col-md-12">
                <label htmlFor="inputEmail4" className="form-label">
                  Type your message
                </label>

                <textarea
                  className="form-control"
                  placeholder="Leave a comment here"
                  onChange={(e) => setMessage(e.target.value)}
                  id="floatingTextarea2"
                ></textarea>
              </div>
              <div>
                <span className="text-danger">{error}</span>
              </div>
              <div className="col-md-12 mt-2">
                <button type="submit" className="sendQuery">
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
