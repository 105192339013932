import './App.css';
import React from 'react';
import { Route, Switch} from "react-router-dom";
import Admin from './component/Admin';
import ContactUs from './component/ContactUs';
import UserStakingHistory from './component/UserStakingHistory';
import Home from './component/Home';
import InsuranceCondition from './component/InsuranceCondition';
import GameRule from './component/GameRule';
import FAQ from './component/FAQ';
import HowToPlay from './component/HowToPlay';
import NFTComponent from './component/NFT/NFTComponent';
import Gaming from './component/Gaming/Gaming';
import Music from './component/Music/Music';
import LandingPage from './component/LandingPage/LandingPage';
import NotFound from './component/NotFound/NotFound';
import '../node_modules/react-rangeslider/lib/index.css'

import LiveStockPriceComponent from './component/LiveStockPriceComponent'
function App() {



  return (


    <div className="App" >
      <Route component={Admin} strict exact path="/polarverse-admin" />

      <Switch>
        <>

          {/* {location.pathname === '/admin' || location.pathname === '/play-to-win' || location.pathname === '/history' ||location.pathname === '/contact-us'||location.pathname === '/how-to-play' ? '' : <LandingPage />} */}
          <Route component={Home} exact strict path="/" />
            <Route component={ContactUs} exact strict path="/contact-us" />
            <Route component={UserStakingHistory} exact strict path="/history" />
            <Route component={GameRule} exact strict path="/game-rules" />
            <Route component={FAQ} exact strict path="/faq" />
            <Route component={InsuranceCondition}  path="/insurance-condition" />
        </>
      </Switch>

    </div>

  );
}

export default App;
