import axios from "axios";
import bigInt from "big-integer";
import React, { useState, useEffect } from "react";
import Contract from "../Contract";
import ChartViewer from "./Chart";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Web3 from "web3";
import { secToTime } from "../utils";
import { IsWeb3 } from "../utils";
import { useMetamask } from "use-metamask";
import { Link } from "react-router-dom";
import Slider from "react-rangeslider";
const MemoizedChartViewer = React.memo(ChartViewer);

export default function ChartContainer() {
  const { connect, metaState } = useMetamask();
  const [data, updateData] = useState([]);
  const [assets, setassets] = useState("BTC");
  const [isInsured, setisInsured] = useState(true);
  const [duration, setDuration] = useState(0);
  const [dummyduration, setdummyDuration] = useState("0");
  const [insuredValue, setInsuredValues] = useState(0);
  const [stakeValue, setstakeValue] = useState(10);
  const [errorMessages, setErrorMessages] = useState("");
  const [invokeButton, setInvokeButton] = useState(false);
  //time
  const [insuredTrueFalse, setinsuredTrueFalse] = useState(false);
  const [userLastBetTime, setuserLastBetTime] = useState(0);
  const [transactionStatus, settransactionStatus] = useState("");
  const [slider, setSlider] = useState(10);
  const chartKey = process.env.CHART_API_KEY;

  useEffect(() => {
    const interval = setInterval(() => {
      axios
        .get(
          `https://min-api.cryptocompare.com/data/v2/histominute?fsym=${assets}&tsym=USD&limit=${slider}`
          // `https://api.binance.com/api/v3/klines?symbol=${assets}USDT&interval=1m&limit=${slider}`
        )
        .then((res) => {
          console.log("res:", res.data.Data.Data)
          updateData(res.data.Data.Data);
          // console.log("call",res)
        });
      //   const val = data;
      //   // var gg = data.map(el => {
      //   //   return {
      //   //     time: el.time * 1000,
      //   //     low: el.low,
      //   //     high: el.high,
      //   //     open: el.open,
      //   //     close: el.close,
      //   //     volume: el.volume
      //   //   }
      //   // })

      // updateData(val);
    }, 1000 * 5);
    return () => {
      window.clearInterval(interval);
    };
  }, [data, assets]);

  useEffect(() => {
    if (userLastBetTime <= 0) {
      return;
    }
    const id = setInterval(timer, 1000);
    return () => clearInterval(id);
  }, [userLastBetTime]);
  const timer = () => {
    setuserLastBetTime(userLastBetTime - 1);
  };

  // get last active bid
  const setActiveBet = async () => {
    setTimeout(async () => {
      try {
        const address = await IsWeb3();
        if (address[0]) {
          let activeBetsFunction = await Contract.returnActiveBetReference();
          let activePrediciton = await Contract.prediction(
            address[0],
            activeBetsFunction[activeBetsFunction.length - 1].ID.toNumber()
          );
          let value = activePrediciton.endTimeStamp.toNumber();
          let utc = Math.floor(new Date().getTime() / 1000.0);
          let lastBetTime = value - utc;
          setuserLastBetTime(lastBetTime);
        }
        // return value;
      } catch (error) {
        console.log(error);
      }
    }, 3000);
  };

  const changeAssests = (e) => {
    setassets(e.target.value);
  };

  useEffect(() => {
    setInsuredUninsuredToggle();
    return () => { };
  }, [isInsured, errorMessages, insuredTrueFalse]);
  if (window.ethereum) {
    // detect Network account change
    window.ethereum.on("chainChanged", function (networkId) {
      setinsuredTrueFalse(!insuredTrueFalse);
    });
    window.ethereum.on("accountsChanged", function (accounts) {
      setinsuredTrueFalse(!insuredTrueFalse);
    });
  }
  async function setInsuredUninsuredToggle() {
    try {
      // setErrorMessages('')
      if (isInsured === true) {
        let abc = await Contract.getInsuredReward();
        setInsuredValues(abc.toString());
      }
      if (isInsured === false) {
        let abc = await Contract.getNonInsuredReward();
        setInsuredValues(abc.toString());
      }
    } catch (error) { }
  }

  useEffect(() => {
    getCurrentTimeStamp();

    return () => { };
  }, [dummyduration]);

  async function getCurrentTimeStamp() {
    let utc = Math.floor(new Date().getTime() / 1000.0);
    let nowUtc = utc + dummyduration * 60;
    setDuration(nowUtc);
  }

  useEffect(() => {
    setActiveBet();
    return () => { };
  }, []);

  async function createNewBid(val) {
    let web3 = new Web3(window.ethereum);

    if (web3) {
      let netId = await web3.eth.net.getId();
      if (netId !== 137) {
        return setErrorMessages("wrong network");
      }
    }

    if (metaState.isAvailable === false) {
      return setErrorMessages("Install Metamask");
    }
    if (window.ethereum || metaState.isConnected === true) {
      let address = await web3.eth.getAccounts();
      if (parseInt(address.length) === 0) {
        return setErrorMessages("Connect Metamask");
      }
    }
    if (dummyduration === 0 || dummyduration === "0") {
      return setErrorMessages("Select Slot");
    }
    if (isNaN(stakeValue) === true) {
      return setErrorMessages("Please input stake value in numbers!");
    }
    if (stakeValue <= 0) {
      return setErrorMessages("Stake Value must be greater than zero");
    }

    let amount = bigInt(stakeValue * 10 ** 18);
    // console.log(assets, isInsured, amount.value, val, dummyduration, "predict");
    try {
      setErrorMessages("");
      settransactionStatus(val.toString());

      let predict = await Contract.predict(
        assets,
        isInsured,
        amount.value,
        val,
        dummyduration
      );
      let transactionConfir = await predict.wait();
      setdummyDuration(0);

      if (transactionConfir) {
        toast("Transaction Successfull");
        getCurrentTimeStamp();
        setActiveBet();
      }
      setErrorMessages("");
    } catch (error) {
      console.log(error);

      try {
        if (error.data.message === "execution reverted: Limit exceeded") {
          return setErrorMessages("execution reverted: Limit exceeded");
        } else if (error.data.message === "not enough funds for gas") {
          return setErrorMessages("Not enough funds for gas");
        } else if (error.data) {
          setErrorMessages(error.data.message);
          // alert(e);
        }
      } catch (error) {
        settransactionStatus("");

        console.log(error);
      }
      settransactionStatus("");
    }
    settransactionStatus("");
    // setDuration(0);
  }

  async function invokeFunction() {
    console.log("check invoke");
    let web3 = new Web3(window.ethereum);

    if (web3) {
      let netId = await web3.eth.net.getId();
      if (netId !== 137) {
        return setErrorMessages("wrong network");
      }
    }

    if (metaState.isAvailable === false) {
      return setErrorMessages("Install Metamask");
    }
    if (window.ethereum || metaState.isConnected === true) {
      let address = await web3.eth.getAccounts();
      if (parseInt(address.length) === 0) {
        return setErrorMessages("Connect Metamask");
      }
    }
    setErrorMessages("");
    try {
      setInvokeButton(true);
      console.log("inc", Contract);
      let ab = await Contract.invokePrediction();
      console.log("abc", ab);
      let waitfortx = await ab.wait();
      if (waitfortx) {
        setInvokeButton(false);
      }
    } catch (error) {
      setInvokeButton(false);

      console.log("error", error);
    }
  }
  function handleSlider(e) {
    // console.log("22222", e);
    setSlider(e);
  }
  return (
    <>
      <div className="container-fluid my-3">
        <ToastContainer
          position="top-right"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <hr className="bg-danger" />
        <h3 className="RubikBeastly text-center ">Lets' Play</h3>
        <div className="row my-2">
          <div className="col-md-12 col-sm-12 col-lg-6 p-0">
            {console.log("ch1234567890", data)}
            {data.length > 0 ?
              <>
                <MemoizedChartViewer data={data} title={assets} />
                <Slider
                  className="ms-5"
                  value={slider}
                  min={10}
                  max={60}
                  orientation="horizontal"
                  onChange={(e) => handleSlider(e)}
                />
              </>
              : <>
                <div style={{
                  height:'100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems:'center'
                }}>Chart is loading...</div>
              </>}
            {/* <ChartViewer data={data} title={assets} /> */}

            {/* <LiveStockPriceComponent  data={data} title={assets}/> */}
          </div>
          <div className="col ">
            <div className="row ">
              <div className="col-md-12 text-center">
                <p className="text-center badge bg-danger badgeTime"></p>
              </div>
              <div className="col mt-1">
                <span className="text-center d-flex justify-content-center">
                  <img
                    src="./assets/CLOCK.gif"
                    className="img-fluid"
                    alt="watch"
                  />
                  <br />
                  {/* <span className="text-center"><Time data={duration} /></span> */}
                </span>
                <p className="text-center">{secToTime(userLastBetTime)}</p>
              </div>
            </div>
            <div className="row  mt-3    mediaNine">
              <div className="col-md-6 col-sm-3  col-lg-6 assets">
                <label htmlFor="assets" className="requireLabel pe-2">
                  Assets
                </label>
                <select
                  name="assets"
                  id="assests"
                  value={assets}
                  onChange={(e) => {
                    changeAssests(e);
                  }}
                  className=" select"
                >
                  <option value="BTC">BTC</option>
                  <option value="ETH">ETH</option>
                  <option value="DAI">DAI</option>
                  <option value="MATIC">MATIC</option>
                </select>
              </div>
              <div className="col-md-6 col-sm-6  col-lg-6 m-0 p-0 duration">
                {/* <select name="duration" id="duration" value={duration} onChange={(e) => { changeTime(e) }} className="mx-4 select"> */}
                <select
                  name="duration"
                  id="duration"
                  value={dummyduration}
                  onChange={(e) => setdummyDuration(e.target.value)}
                  className=" select"
                >
                  <option value={0}>Select time</option>
                  <option value={5}>5 min</option>
                  <option value={10}>10 min</option>
                  <option value={15}>15 min</option>
                  <option value={20}>20 min</option>
                  {/* {slots.length > 0
                  ? slots.map((e, index) => {
                      return e !== "Slot expired" ? (
                        <option key={index} value={e}>
                          {e} GMT{" "}
                        </option>
                      ) : (
                        ""
                      );
                    })
                  : "Slot Expired"} */}
                </select>
                <label htmlFor="cars" className="requireLabel ps-2">
                  Duration
                </label>
              </div>
            </div>
            <div className="row mt-5 d-flex justify-content-center ">
              <div className="col-md-6 ps-md-5 col-sm-6 col-xl-6 stake">
                <label htmlFor="cars" className="requireLabel ">
                  Stake
                </label>

                <input
                  type="text"
                  placeholder="0"
                  value={stakeValue}
                  onChange={(e) => setstakeValue(e.target.value)}
                  className="stakeInputBox mx-4"
                  name=""
                  id=""
                />
              </div>
              <div className="col-md-6 col-sm-3  col-xl-6 payout">
                <button className="stakeInputBoxs">
                  {insuredValue ? insuredValue : "0 "} %
                </button>
                <label htmlFor="cars" className="requireLabel ps-2">
                  Payout
                </label>
              </div>

              <div className="errorMessage mt-3">
                {errorMessages === "Select Slot" ? "Select Slot" : ""}
                {errorMessages === "Refresh"
                  ? "Refresh the page if payout is not shown"
                  : ""}
                {errorMessages === "wrong network"
                  ? "Please switch to POLYGON Mainnet"
                  : ""}
                {errorMessages === "Install Metamask" ? "Install Metamask" : ""}
                {errorMessages === "Connect Metamask" ? "Connect Metamask" : ""}

                {errorMessages === "Stake Value must be greater than zero"
                  ? errorMessages
                  : ""}
                {errorMessages === "Please input stake value in numbers!"
                  ? errorMessages
                  : ""}
                {errorMessages ===
                  "execution reverted: ERC20: transfer amount exceeds balance"
                  ? "You do not have enough PolarWin Tokens..!"
                  : ""}
                {errorMessages === "Not enough funds for gas"
                  ? "Not enough funds for gas"
                  : ""}
                {errorMessages === "execution reverted: Limit exceeded"
                  ? "Limit Exceeded"
                  : ""}
                {/* {errorMessages} */}
              </div>
            </div>
            <div className="row mt-5 ">
              <div className="col d-flex justify-content-center">
                <button
                  className={`insuredBtn  ${isInsured === true ? "focus" : ""}`}
                  onClick={() => setisInsured(true)}
                  type="button"
                >
                  Insured
                </button>
                <button
                  className={`unInsuredBtn   ${isInsured === false ? "unInsuredBtnFocus" : ""
                    }`}
                  onClick={() => setisInsured(false)}
                >
                  Uninsured
                </button>
                <span
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title="Get Insured by the Amount that you paid for the game. If you loss any amount then player got some remit of there token amount."
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="white"
                    className="bi bi-info-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                  </svg>
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col biddingButtonCol">
                <button className="upBtn" onClick={() => createNewBid(true)}>
                  {transactionStatus === "true" ? (
                    <div className="spinner-border" role="status"></div>
                  ) : (
                    <span className="">
                      UP{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-arrow-up-right arrowUp"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"
                        />
                      </svg>
                    </span>
                  )}
                </button>
                <button
                  className="downBtn "
                  onClick={() => createNewBid(false)}
                >
                  {transactionStatus === "false" ? (
                    <div className="spinner-border" role="status"></div>
                  ) : (
                    <span>
                      DOWN{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-arrow-down-left arrowDown"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M2 13.5a.5.5 0 0 0 .5.5h6a.5.5 0 0 0 0-1H3.707L13.854 2.854a.5.5 0 0 0-.708-.708L3 12.293V7.5a.5.5 0 0 0-1 0v6z"
                        />
                      </svg>{" "}
                    </span>
                  )}
                </button>
              </div>
            </div>
            <div className="col d-flex justify-content-center">
              {invokeButton ? (
                <>
                  <div className="spinner-border mt-4" role="status"></div>
                </>
              ) : (
                <button className=" getResultButton" onClick={invokeFunction}>
                  Get Result
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
