import React, { useState, useEffect } from 'react'
import Contract from '../Contract'
export default function WinnerSlider() {
    const [finalWinnerArray, setfinalWinnerArray] = useState([])


    useEffect(() => {
        getWinner();
        return () => {
            setfinalWinnerArray([])
        }
    }, [])


    async function getWinner() {
        try {
            let endBetsFunction = await Contract.returnEndedBetReference();
            let winnerArray = [];
            let endedPrediciton = [];
            let abc = {};
            let removeDuplicateAddress = endBetsFunction.map((item) => item[0])
            let uniqueAddress = [...new Set(removeDuplicateAddress)];
            for (let x = 0; x < uniqueAddress.length; x++) {
                let playerBalance = await Contract.playerBalance(uniqueAddress[x]);
                let zero = 0;
                if (playerBalance > zero) {
                    abc.address = uniqueAddress[x];
                    abc.playerBalance = playerBalance;
                    endedPrediciton.push({ "address": abc.address, "playerbalance": abc.playerBalance });

                    if (winnerArray.length === 4) {
                        break;
                    }
                }
            }
            setfinalWinnerArray(endedPrediciton)
        } catch (error) {

        }

    }
    function removeEighteenDigit(val) {
        let removeEighteenDigits = val;
        removeEighteenDigits = removeEighteenDigits.substring(0, removeEighteenDigits.length - 18)
        return removeEighteenDigits;
    }


    return (
        <div className="container-fluid leadersBoard">
            <hr className="bg-danger" />

            <div className="row mt-5">
                <h1 className='text-center'> <u>Leaders Board</u></h1>
                <div className="col-md-6 col-lg-6 col-xl-6 col-sm-12">
                    <img src="./assets/winn.png" className="img-fluid" alt="img" width="550"></img>

                </div>
                <div className="col-md-6">
                    <div className="row d-flex justify-content-center text-center">
                        { finalWinnerArray.length !==0? finalWinnerArray.map((item, index) => {

                            return (
                                <div className="col-md-5 mb-3  winnerCol" key={index}>
                                    <h5><span><svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" className="bi bi-trophy" viewBox="0 0 16 16">
                                        <path d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5c0 .538-.012 1.05-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33.076 33.076 0 0 1 2.5.5zm.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935zm10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935zM3.504 1c.007.517.026 1.006.056 1.469.13 2.028.457 3.546.87 4.667C5.294 9.48 6.484 10 7 10a.5.5 0 0 1 .5.5v2.61a1 1 0 0 1-.757.97l-1.426.356a.5.5 0 0 0-.179.085L4.5 15h7l-.638-.479a.501.501 0 0 0-.18-.085l-1.425-.356a1 1 0 0 1-.757-.97V10.5A.5.5 0 0 1 9 10c.516 0 1.706-.52 2.57-2.864.413-1.12.74-2.64.87-4.667.03-.463.049-.952.056-1.469H3.504z" />
                                    </svg></span> Winner</h5>
                                    <p className="text-primary winnerAddress"> <span className="winnerAddress">{item.address}</span></p>

                                    <button className="winnerBtn text-white"> {removeEighteenDigit(item.playerbalance.toString())}-PWIN</button>
                                </div>

                            )
                        }) :
                            <div className="container">
                                <div className="row">
                                    <div className="">
                                        <div className="lead noWinnerDiv ">
                                            <span className="NoWinner">You can get listed here...! </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }


                    </div>
                </div>

            </div>

        </div>
    )
}
