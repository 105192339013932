import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CurrentContract from "../Contract";
import Polarwin from "../Polarwin";
import Web3 from "web3"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
let ab = CurrentContract;

export default function Admin() {
  const [insuredPayout, setInsuredPayout] = useState(0);
  const [unInsuredPayout, setUnInsuredPayout] = useState(0);
  const [stakePercentage, setStakePercentage] = useState(0);
  const [stakeDuration, setStakeDuration] = useState(0);

  const [balanceOf, setbalanceOf] = useState("");
  const [invalid, setValid] = useState(false);
  const [toggle, setToggle] = useState(true);
  useEffect(() => {
    checkOwner();
    setPredefinedValues();
    setValid(false);
  }, []);

  async function checkOwner(){
    try {
      if(window.ethereum){
        let web3=new Web3(window.ethereum)
        let account=await web3.eth.getAccounts()

        let admin =await Polarwin.owner();
        if(admin === account[0]){
          return setValid(true);
        }
        else{
          return setValid(false)
        }
    
      }

    } catch (error) {
      
    }


  }
  async function setPredefinedValues() {
    try {
      let balance = await Polarwin.balanceOf(ab.address);

      let insuredReward = await ab.getInsuredReward();
      let noninsuredReward = await ab.getNonInsuredReward();
      // let contractBalance = await ab.balanceOf(ab.address);
      let stakePercentagee = await ab.getStackRewardPer();
      let stakeDurationn = await ab.getStackDuration();

      setStakePercentage(stakePercentagee.toNumber());
      setInsuredPayout(insuredReward.toNumber());
      setUnInsuredPayout(noninsuredReward.toNumber());
      let removeEighteenDigit = balance.toString();
      removeEighteenDigit = removeEighteenDigit.substring(
        0,
        removeEighteenDigit.length - 18
      );

      setbalanceOf(removeEighteenDigit);

      let seconds = Number(stakeDurationn.toNumber());

      let d = Math.floor(seconds / (3600 * 24));
      var dDisplay = d > 0 ? d : "";
      setStakeDuration(dDisplay);
    } catch (error) {
      console.log(error);
    }
  }

  async function updatePolarwinDetails(e) {
    e.preventDefault();
    try {
      await ab.setReward(insuredPayout, unInsuredPayout);
      let confirm = await ab.setStackingDetails(stakePercentage, stakeDuration);
      let waitForConfirm =await confirm.wait();
      if(waitForConfirm){
        toast("Transaction Successfull");
        setPredefinedValues()

      }
    } catch (error) {
      alert(error.data.message);
    }
  }

  if (invalid === true) {
    return (
      <div
        className={`admin sb-nav-fixed  ${
          toggle === false ? "sb-sidenav-toggled" : ""
        }`}
      >
        <ToastContainer
          position="top-right"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark ">
          {/* <!-- Navbar Brand--> */}

          <Link className="navbar-brand text-center" to="/">
            <img src="../assets/bearLogo.png" className="" alt="logo" />
          </Link>
          {/* <!-- Sidebar Toggle--> */}
          <button
            className="btn btn-link btn-sm order-1 border order-lg-0 me-4 me-lg-0"
            onClick={() => {
              setToggle(!toggle);
            }}
            id="sidebarToggle"
          >
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-list"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                />
              </svg>
            </span>
          </button>
          {/* <!-- Navbar Search--> */}

          {/* <!-- Navbar--> */}

          <ul className="navbar-nav d-md-inline-block ms-auto me-0 me-md-3 my-2 my-md-0   ">
            <li className="nav-item dropdown">
              <button
                className=" hrefRemove bg-dark"
                id="navbarDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="23"
                    fill="rgb(0,250,0)"
                    className="bi bi-person-workspace"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H4Zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                    <path d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.373 5.373 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2H2Z" />
                  </svg>
                </span>{" "}
                Admin
              </button>
              <ul
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby="navbarDropdown"
              >
                <li>
                  <button className="dropdown-item">Settings</button>
                </li>
                <li>
                  <button className="dropdown-item">Activity Log</button>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                {/* <li><button className="dropdown-item" >Logout</a></li> */}
              </ul>
            </li>
          </ul>
        </nav>

        <div id="layoutSidenav">
          <div id="layoutSidenav_nav">
            <nav
              className="sb-sidenav accordion sb-sidenav-dark"
              id="sidenavAccordion"
            >
              <div className="sb-sidenav-menu">
                <div className="nav">
                  <Link
                    className="nav-link border-bottom border-top"
                    to="/admin"
                  >
                    Setting
                  </Link>
                </div>
              </div>

              {/* <div className="sb-sidenav-footer">
                                <div className="small">Logged in as:</div>
                                Start Bootstrap
                            </div> */}
            </nav>
          </div>
          <div id="layoutSidenav_content">
            <main>
              <form
                className="mx-3 my-3 px-3  py-3 border shadow-sm rounded "
                onSubmit={updatePolarwinDetails}
              >
                <div className="form-group col-md-12   text-center ">
                  <label htmlFor="contractBalance">Contract Balance</label>
                  <h4 className="card-title ">
                    {balanceOf ? balanceOf : "-"}{" "}
                    <span className="text-primary"> PWIN </span>
                  </h4>
                </div>
                <div className="form-row d-flex justify-content-around mb-4">
                  <div className="form-group  col-md-4 col-sm-4">
                    <label htmlFor="insuredPayout">Insured Payout</label>
                    <input
                      type="text"
                      className="form-control col-xs-2"
                      value={insuredPayout}
                      onChange={(e) => {
                        setInsuredPayout(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group  col-md-4 col-sm-4">
                    <label htmlFor="unINsuredPayout">UnInsured Payout</label>
                    <input
                      type="text"
                      className="form-control col-xs-2"
                      value={unInsuredPayout}
                      onChange={(e) => {
                        setUnInsuredPayout(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="form-row d-flex d-flex justify-content-around mb-4">
                  <div className="form-group  col-md-4 col-sm-4 ">
                    <label htmlFor="stakePercentage">Stake Percentage</label>
                    <input
                      type="text"
                      className="form-control col-xs-2"
                      value={stakePercentage}
                      onChange={(e) => {
                        setStakePercentage(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group  col-md-4 col-sm-4">
                    <label htmlFor="stakeDuration">
                      Stake Duration (in days)
                    </label>
                    <input
                      type="text"
                      className="form-control col-xs-2"
                      value={stakeDuration}
                      onChange={(e) => {
                        setStakeDuration(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="text-center">
                  <button type="submit" className="p-1 btn-primary">
                    Update
                  </button>
                </div>
              </form>

              {/* //slots  */}
            </main>

            <footer className="py-4 bg-light mt-auto">
              <div className="container-fluid px-4">
                <div className="d-flex align-items-center justify-content-between small">
                  <div className="text-muted">
                    Copyright &copy; POLARWIN 2024
                  </div>
                  <div>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                    &middot;
                    <Link to="/terms-and-conditions">
                      Terms &amp; Conditions
                    </Link>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    );
  } else {
    return (<div className="text-center mt-5 pt-5"> 
    <h1>You are not authorize </h1></div>);
  }
}
